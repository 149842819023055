/* width */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #d0d0d0;
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #969595;
  border-radius: 100px;
  height: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}
